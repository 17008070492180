import React, {useState, useEffect} from "react";
import { Line } from "react-chartjs-2";
import { AppHelper } from '../../AppHelper';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

// Register components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function ReportCardChartSpark({argData, argColor}) {
  const data = {
    labels: ["1", "2", "3", "4", "5", "6", "7"], // Small set of labels
    datasets: [
      {
        label: "",
        data: [],// [3, 4, 2, 7, 5, 6, 8, 12], // Sample values for sparkline
        fill: false,
        borderColor: "#fff",//"rgba(75, 192, 192, 1)",
        backgroundColor: "#fff",//"rgba(75, 192, 192, 0.2)",
        tension: 0.4,
        pointRadius: 2, // No points on the line
        borderWidth: 2,
      },
    ],
  };
  
  const [chartData, setChartData] = useState(data);

  useEffect(()=>{
    
  }, []);
  useEffect(()=>{
    if(!argData) return;
    var _chartData = {...chartData};
    _chartData.labels = data.labels.slice(0, argData.length);
    _chartData.datasets[0].data = argData.map(r=>r.score);
    _chartData.datasets[0].borderColor = argColor;
    setChartData(_chartData);
    //console.log('chart data spark', argData, _chartData);
  }, [argData]);

  const options = {
    responsive: true,
    maintainAspectRatio: false, // To control the chart's height
    interaction: {
      mode: 'nearest', // Shows tooltip when hovering near the line
      intersect: false // Ensures tooltip appears even when not directly on a point
    },
    plugins: {
      legend: { display: false }, // Hide the legend
      //title: { display: true, text: "Grammar", position: "bottom" },
      tooltip: {
        enabled: false, caretSize: 0, clip: false,
        position: "nearest", // Helps position tooltip better
        yAlign: "bottom", // Adjusts alignment
        xAlign: "center",
        external: function(context) {
          let tooltip = document.getElementById('chart-tooltip');
          if (!tooltip) {
            tooltip = document.createElement('div');
            tooltip.id = 'chart-tooltip';
            tooltip.style.position = 'absolute';
            tooltip.style.backgroundColor = '#000';
            tooltip.style.color = '#fff';
            tooltip.style.border = '1px solid #ccc';
            tooltip.style.padding = '5px';
            tooltip.style.borderRadius = '5px';
            tooltip.style.fontSize = '.8rem';
            tooltip.style.pointerEvents = 'none';
            tooltip.style.zIndex = '1000';
            document.body.appendChild(tooltip);
          }
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltip.style.opacity = '0';
            return;
          }
          const position = context.chart.canvas.getBoundingClientRect();
          tooltip.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltip.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltip.style.opacity = '1';
          var _title = tooltipModel.title.join('<br>');
          var _score = tooltipModel.body.map(b => b.lines).join('<br>');
          var _time = tooltipModel.body.map(b => b.after).join('<br>');
          tooltip.innerHTML = `<b>${_title}</b><br>${_score}<br><span style="font-size:.7rem; opacity:.7">${_time}</span>`;
        },
        callbacks:{
          title: (tooltipItems) => {
            const index = tooltipItems[0].dataIndex;
            return argData[index].title; // Show 'name' in tooltip title
          },
          label: (tooltipItem) => `Score: ${tooltipItem.raw}/10`,
          afterLabel: (tooltipItem) => {
            const index = tooltipItem.dataIndex;
            return `${argData[index].time}`; // Add date as an extra line
          },
        }
      },
      datalabels: { display: false }
    },
    scales: {
      x: {
        type: 'linear',
        ticks: { display: false },
        grid: { display: true },
      },
      y: { display: false, min:0, max: 11 }, // Hide y-axis
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };

  return (
    <div style={{ height: "5rem" }}> {/* width: "100px", height: "40px"  Small chart size */}
      <Line data={chartData} options={options} key={JSON.stringify(chartData)} />
    </div>
  );
}