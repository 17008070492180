import '../../css/report-card.css';
import React, {useState, useEffect, useRef} from "react";
import { CircularProgress } from '@mui/material';
import { Card, CardContent, Container, Grid, Paper, 
  MenuItem, Select, Typography } from '@mui/material';
import moment from 'moment';

import { AppHelper } from '../../AppHelper';
import AppTopBar from '../common/app-top-bar';
import { useEssayContext } from "../../contexts/context-essay";
import ReportCardChart from "./report-card-chart";
import ReportCardChartPolar from "./report-card-chart-polar";
import ReportCardChartSpark from "./report-card-chart-spark";
const dayjs = require('dayjs');

export default function ReportCard() {
  const [oUser, setOUser] = useState(AppHelper.userCacheGet());
  
  const [loading, setLoading] = useState(true);
  const refTopBar = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [chartDataPolar, setChartDataPolar] = useState(null);
  const [chartData1, setChartData1] = useState(null);
  const [chartData2, setChartData2] = useState(null);
  const [chartData3, setChartData3] = useState(null);
  const [chartData4, setChartData4] = useState(null);
  const [chartData5, setChartData5] = useState(null);
  const [chartData6, setChartData6] = useState(null);
  const {essayList, isEssayListLoaded } = useEssayContext();
  const [essayListForReport, setEssayListForReport] = useState(null);
  const [bestEssay, setBestEssay] = useState(null);
  const [profileName, setProfileName] = useState('');
  const todayTitle = moment().format(`DD MMM 'YY`);
  const [profiles, setProfiles] = React.useState([]);
  const [profileId, setProfileId] = useState('');

  const categoryTitles = [
    "Relevance & Logic", "Development", 
    "Grammar", "Spelling", 
    "Vocabulary", "Structure & Flow"
  ]

  useEffect(() => {
    AppHelper.apiAuditPost("P-RC", "");
  }, []);
  useEffect(() => {
    if(oUser) setProfileName(oUser.name);
  }, [oUser]);
  useEffect(() => {
    if(!essayList || essayList.length<1) return;
    setEssayListForReport(essayList);
    var _user = AppHelper.userCacheGet();
    if (_user && _user.grade) {
      setProfileId(_user.profileId);
      fetchProfiles(_user);
    }
  }, [isEssayListLoaded]);
  useEffect(()=>{
    if(!essayListForReport) return;
    fetchScores();
  }, [essayListForReport]);
  
  const fetchScores = async () => {
    try {
		  setLoading(true);
      var _iReport = 0;
      var _recEssays = [];
      var _chartData = [];
      var _essayArr = AppHelper.sortList(essayListForReport, 'tCode', 2);
      var _top7 = _essayArr.filter(x=>!x.isSample);//.slice(0,1);
      var _chartData1 = [];
      var _chartData2 = [];
      var _chartData3 = [];
      var _chartData4 = [];
      var _chartData5 = [];
      var _chartData6 = [];
      //_top7 = AppHelper.sortList(_top7, 'tCode', 1);
      //console.log('sorted list', _top7);

      var _bestEssay = {score: 0, title:'', time: '', avgScore: 0, essayCount: 0};
      var _sumScore = 0;
      for(var i=0; i<_top7.length; i++)
      {
        if(_iReport<7){
          var _r = _top7[i];
          const _resLastAttempt = await AppHelper.apiPost('api/essay/attempt/IsLastAttempt', {token: oUser.token, essayId: _r.essayId});
          //console.log('_resLastAttempt',_resLastAttempt);
          if(_resLastAttempt && _resLastAttempt.status){
            const _res = await AppHelper.apiPost('api/scores', {token: oUser.token, essayId: _r.essayId, profileId: _r.profileId});
            if(_res && _res.length>0){
              _recEssays.push({..._r, scoreResult: _res});
              var _recScore = Math.round(_res[0].overall_score);
              if(_recScore>_bestEssay.score){
                _bestEssay = {score: _recScore, title: _r.topic, time: _r.tTitle, avgScore: 0, essayCount: 0 };
              }
              _sumScore += _recScore;
              _chartData.push({
                title: _r.topic,
                score: _recScore,
                time: _r.tTitle,
                tCode: _r.tCode,
              });
              var _recEssayScore = [
                { score: Math.floor(_res[0].CR_score/10), title: _r.topic, time: _r.tTitle },
                { score: Math.floor(_res[0].CT_score/10), title: _r.topic, time: _r.tTitle },
                { score:_res[0].GM_essayScore, title: _r.topic, time: _r.tTitle },
                { score:_res[0].SP_essayScore, title: _r.topic, time: _r.tTitle },
                { score: Math.floor(_res[0].VC_score/10), title: _r.topic, time: _r.tTitle },
                { score: Math.floor(_res[0].ST_score/10), title: _r.topic, time: _r.tTitle },
              ];
              if(_iReport==0)
              {
                setChartDataPolar(_recEssayScore);
              }
              _chartData1.push(_recEssayScore[0]);
              _chartData2.push(_recEssayScore[1]);
              _chartData3.push(_recEssayScore[2]);
              _chartData4.push(_recEssayScore[3]);
              _chartData5.push(_recEssayScore[4]);
              _chartData6.push(_recEssayScore[5]);
              _iReport++;
            }
            else{
              console.log('score api error', _r.essayId, _res);
            }
          }
        }
      }
      if(_iReport<1){
        setChartDataPolar([]);
      }
      _bestEssay.essayCount = _iReport;// _top7.length;
      _bestEssay.avgScore = _sumScore==0 ? 0 : Math.round(_sumScore/_iReport);
      setBestEssay(_bestEssay);

      setChartData(_chartData.reverse());
      setChartData1(_chartData1.reverse());
      setChartData2(_chartData2.reverse());
      setChartData3(_chartData3.reverse());
      setChartData4(_chartData4.reverse());
      setChartData5(_chartData5.reverse());
      setChartData6(_chartData6.reverse());
      
      _recEssays.forEach(r => {
        // if(r.scoreResult && r.scoreResult.length>0)
        //   _chartData.push(Math.round(r.scoreResult[0].overall_score));
      });
      //setChartData(_chartData);

      // if (response && response.status && response.data) {
      //   setProfiles(response.data);
      // } else {
      //   console.error('Failed to fetch profiles:', response);
      // }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching score:', error);
    } finally {
		  setLoading(false); 
		}
  };

  const fetchEssayList = async(argProfileId)=>{
    var _user = AppHelper.userCacheGet();
    if (!_user) return;
    var _res = await AppHelper.apiPost('api/essay/list/v4', {token: _user.token, profileId: argProfileId});
    if(!_res.status){
      console.log('Error', _res.message);
      return;
    }
    var _arr = [..._res.data];
    _arr.forEach(x=>{
      x.time = dayjs(x.timestampUtc).format("DD MMM YY' hh:mm A");
      x.tTitle = dayjs(x.timestampUtc).format("DD MMM YY' hh:mm A");
      x.tCode = dayjs(x.timestampUtc).format("YYYYMMDDHHmmss");
    });
    _arr = AppHelper.sortList(_arr, 'tCode', 2);
    setEssayListForReport(_arr);
  }
  
  const fetchProfiles = async (argUser) => {
    try {
      const response = await AppHelper.apiPost('api/profile/list', { token: argUser.token });
      if (response && response.status && response.data) {
        setProfiles(response.data);
      } else {
        console.error('Failed to fetch profiles:', response);
      }
    } catch (error) {
      console.error('Error fetching profiles:', error);
    }
  };
  const profileSelect = async (arg) => {
    var _profile = profiles.find(x=>x.profileId == arg.target.value);
    if(!_profile) return;
    setProfileName(_profile.name);
    setProfileId(arg.target.value);
    fetchEssayList(arg.target.value);
  }

  return (
  <div>
    <AppTopBar ref={refTopBar}/>
    {isEssayListLoaded &&
    <Grid container>
      <Grid item xs={12} textAlign={'right'}>
        {profiles && profiles.length > 1 && (
          <Select labelId='lblProfile'
            value={profileId}
            onChange={profileSelect}
            label=""
            sx={{ "& fieldset": { border: 'none' }}}
          >
            {profiles.map((item) => (
              <MenuItem key={item.profileId} value={item.profileId}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
    </Grid>
    }
    <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
      <Paper sx={{ 
        my: { 
          xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'70vh', xs:'70vh'}, 
          //backgroundColor:'rgb(235, 217, 180)' 
        }} elevation={3}>
          <Grid container>
            <Grid item xs={6}>
              <div style={{marginBottom:0, fontSize:'1.8rem', fontWeight:'bold'}}>Report Card</div>
            </Grid>
            <Grid item xs={6} display={'flex'} alignItems={'flex-end'} justifyContent={'flex-start'} flexDirection={'column'}>
              <div style={{fontSize:'2rem', color:'rgba(19,130,117,1)', fontWeight:700, fontFamily:'Roboto", "Helvetica", "Arial", sans-serif' }}>
              EssayAce
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <div style={{fontSize:'1rem', marginLeft:'.2rem'}}>
                {oUser &&
                <div style={{fontWeight:'bold'}}>{profileName}</div>
                }
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{fontSize:'.8rem', textAlign:'right'}}>
                {todayTitle}
              </div>
            </Grid>
          </Grid>
          { loading &&
            <div style={{margin:'2rem', textAlign:'center'}}>
              <CircularProgress />
            </div>
          }
          { !loading &&
          <>
          { bestEssay &&
          <Grid container sx={{marginBottom:'1rem', marginTop:'1rem'}}>
            <Grid item xs={12} marginBottom={'.5rem'}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography variant="h5" component="div" style={{fontWeight:'bold', color:'rgba(19,130,117,1)'}}>
                        {bestEssay.score}%
                      </Typography>
                    </Grid>
                    <Grid item xs={7} textAlign={'right'}>
                      <Typography variant="body2" color="text.primary" fontWeight={'normal'}>
                        Best Essay
                      </Typography>
                      <div style={{fontSize:'.8rem'}}>
                        {bestEssay.time}
                      </div>
                    </Grid>
                  </Grid>
                  <div>{bestEssay.title}</div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography variant="h5" component="div" style={{fontWeight:'bold', color:'rgba(19,130,117,1)'}}>
                        {bestEssay.avgScore}%
                      </Typography>
                    </Grid>
                    <Grid item xs={7} textAlign={'right'}>
                      <Typography variant="body2" color="text.primary">
                        Avg. Score
                      </Typography>
                      {/* <div style={{fontSize:'.8rem'}}>
                        {bestEssay.time}
                      </div> */}
                    </Grid>
                  </Grid>
                  <div>Across {bestEssay.essayCount} essays</div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          }
          <div>
            <ReportCardChart argData={chartData} />
          </div>
          <div style={{marginBottom:'-3rem'}}>
            <h3>Strength Analysis</h3>
          </div>
          <Grid container>
            <Grid item xs={12} md={12} sx={{background:'rgba(0,0,0,.00)'}}>
              <ReportCardChartPolar argData={chartDataPolar} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={6}>
                  <div className='report-card-chart-sparkline-title'>{categoryTitles[5]}</div>
                  <div className="report-card-chart-sparkline">
                    <ReportCardChartSpark argData={chartData6} argColor={'rgba(161, 136, 127, 0.8)'}/>
                  </div>
                </Grid>
                <Grid item xs={6}>
                <div className='report-card-chart-sparkline-title'>{categoryTitles[0]}</div>
                  <div className="report-card-chart-sparkline">
                    <ReportCardChartSpark argData={chartData1} argColor={'rgba(255, 99, 132, 0.6)'} />
                  </div>
                </Grid>
                <Grid item xs={6}>
                <div className='report-card-chart-sparkline-title'>{categoryTitles[4]}</div>
                  <div className="report-card-chart-sparkline">
                    <ReportCardChartSpark argData={chartData5} argColor={'rgba(153, 102, 255, 0.6)'}/>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className='report-card-chart-sparkline-title'>{categoryTitles[1]}</div>
                  <div className="report-card-chart-sparkline">
                    <ReportCardChartSpark argData={chartData2} argColor={'rgba(54, 162, 235, 0.6)'}/>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className='report-card-chart-sparkline-title'>{categoryTitles[3]}</div>
                  <div className="report-card-chart-sparkline">
                    <ReportCardChartSpark argData={chartData4} argColor={'rgba(75, 192, 192, 0.6)'}/>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className='report-card-chart-sparkline-title'>{categoryTitles[2]}</div>
                  <div className="report-card-chart-sparkline">
                    <ReportCardChartSpark argData={chartData3} argColor={'rgba(255, 206, 86, 0.6)'}/>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          </>
          }
          {/* <Divider /> */}
        {/* <nav aria-label="App Version">
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={'Last Login: ' + ((oUser.metadata && oUser.metadata.lastSignInTime) ? oUser.metadata.lastSignInTime : '')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary={appVer} sx={{color:'rgba(0,0,0,0.3)', textAlign:'right'}}  primaryTypographyProps={{fontSize: '0.7rem', fontStyle:'italic'}}/>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="#simple-list">
                <ListItemText primary="Spam" />
              </ListItemButton>
            </ListItem>
          </List>
        </nav> */}
      </Paper>
      {/* <AppFooterFeedback/> */}
    </Container>
    <div style={{height:0}}>&nbsp;</div>
  </div>
  );
}
