import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppHelper } from '../../AppHelper';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Card, Chip, chipClasses, Grid, CircularProgress } from '@mui/material';
import { useEssayContext } from "../../contexts/context-essay";

export default function EssayAttempt({ essayId, attemptSelect }) {
	//const { essayList, isEssayListLoaded } = useEssayContext();
	const [loading, setLoading] = useState(true);
	const [attempts, setAttempts] = useState([]); // Assuming essay data state
	const navigate = useNavigate();
	const location = useLocation();		

	const fetchEssayDetail = async (argId) => {
		var _user = AppHelper.userCacheGet();
		if (!_user) {
			_user = { token: 'NA' };
		}
		setLoading(true);


		AppHelper.apiPost('api/essay/detail', { token: _user.token, essayId: essayId }).then(_res => {

			if (_res && _res.status) {
				if (_res.essayAttempt && _res.essayAttempt.length > 0) {
					/*const attempts = [
					{
					  current:1,
					  essayId: essayId,
					  label: "Latest",  
					  percentage: Math.floor(Math.random() * 20) + 80, // Random percentage (1-100)
					  margin: 0,
					},
					..._res.essayAttempt.map((x, index) => ({
					  current:0,
					  essayId: x.essayId,
					  label: "Attempt " + (index + 1),
					  percentage: Math.floor(Math.random() * 100) + 1, // Random percentage (1-100)
					  margin: 0,
					}))
				  ];*/

					const attempts = _res.essayAttempt.map((x, index) => ({
						cls: 'app-bg-g',
						essayId: x.essayId,
						label: "Attemptx " + (index + 1),
						//percentage: 0,//Math.floor(Math.random() * 40) + 60, // Random percentage (1-100)
						percentage: Math.round(x.score[0].overall_score),
						margin: 0,
					}));

					
					attempts.push({
						cls: 'app-bg-g',
						essayId: _res.essayAttempt[0].parentId,
						label: "Attempt " + (attempts.length + 1),
						percentage: Math.round(_res.essayAttempt[0].parentScore[0].overall_score),
						margin: 0,
					});
				
					for (let i = 0; i < attempts.length - 1; i++) {
						attempts[i].margin = attempts[i].percentage - attempts[i + 1].percentage;
					}


					for (let i = 0; i < attempts.length; i++) {
						if (attempts[i].essayId == essayId) {
							attempts[i].cls = 'app-bg-b';
							break;
						}
					}

					for (let i = 1, index = attempts.length - 1; i <= attempts.length - 1; i++, index--) {
						attempts[i].label = "Attempt " + (index);// + " " + attempts[i].essayId;
					}

					if (attempts.length > 0) {
						attempts[0].label = 'Latest';// + " " + attempts[0].essayId;
					}

					setAttempts(attempts);
					setLoading(false);
					
				}
				else setLoading(false);

			}
			setLoading(false);


		});


	}

	useEffect(() => {
		//if (!isEssayListLoaded) return;
		fetchEssayDetail(essayId);
	}, []);
	const onAttemptSelect = (arg) => {		
		if (location.pathname.includes("share") || location.pathname.includes("detail")) {
			navigate(`/essay/detail/${arg.essayId}`);
		}
		else if (attemptSelect) attemptSelect(arg);
	}

	return (
		<Grid container pt={0.5}>
			<Grid item xs={12} md={12}>
				{loading ? (
					<Box display="flex" justifyContent="center" alignItems="center" height="100px">
						Loading..<CircularProgress />
					</Box>
				) : (
					<>
						{attempts.length <= 1 &&
							<div id="essayAttemptsCard">
								{/* No attempt */}
								
							</div>
						}
						{attempts.length > 1 &&
							<Card variant="outlined" sx={{ padding: 1.5, marginRight: 0, borderRadius: '20px' }}>
								<div id="essayAttemptsCard">
									<div id="boxPageBreak"></div>
									<Box display="flex" width="100%" sx={{ marginBottom: '1rem' }}>
										<Box flexGrow={1}>
											<Box id="boxScoreTitle" sx={{ fontWeight: 'bold', fontSize: '2rem' }}>
												OTHER ATTEMPTS
											</Box>
											<Box>
												{attempts.map((attempt, index) => (
													<Grid container mb={0.6} key={index}>
														<Grid item xs={12}>
															<Box display='flex' width='100%'>
																<Box flexGrow={1} paddingRight={'.2rem'}>
																	<Box
																		flexGrow={1}
																		sx={{
																			border: '1px solid #333',
																			backgroundColor: '#fff',
																			color: '#fff',
																			borderRadius: '50px',
																			display: 'flex',
																			whiteSpace: 'nowrap',
																			overflow: 'hidden',
																			textOverflow: 'ellipsis',
																		}}
																	>
																		<Box
																			className={attempt.cls}
																			sx={{
																				borderRadius: "50px",
																				width: `${attempt.percentage}%`,
																				height: "100%",
																				padding: "3px 0 3px 15px",
																				alignItems: "center",
																				display: "flex",
																				fontWeight: "bold",
																			}}
																		>
																			{attempt.label} {attempt.percentage}%
																		</Box>
																	</Box>
																</Box>
																<Box>
																	<Chip
																		label={attempt.margin == 0 ? "—" : attempt.margin > 0 ? `+${attempt.margin}%` : `${attempt.margin}%`}
																		className={attempt.margin < 0 ? "" : "app-bg-g"}
																		sx={{
																			color: attempt.margin < 0 ? "red" : attempt.margin === 0 ? "transparent" : "#fff",
																			backgroundColor: attempt.margin === 0 ? "transparent" : attempt.margin < 0 ? "#fff" : "inherit",
																			fontWeight: "bold",
																			marginLeft: "2px",
																			minWidth: "60px",
																			width: "60px",
																			border: attempt.margin < 0 ? "1px solid red" : "none",
																			visibility: attempt.margin === 0 ? "hidden" : "visible",
																			[`& .${chipClasses.icon}`]: {
																				color: "#fff",
																			},
																		}}
																	/>
																	<Chip
																		icon={<OpenInNewIcon />}
																		className="app-bg-g"
																		disabled={attempt.cls === "app-bg-b"}
																		onClick={() => onAttemptSelect(attempt)}
																		sx={{
																			cursor: attempt.cls === "app-bg-b" ? "not-allowed" : "pointer",
																			color: "#fff",
																			fontWeight: "bold",
																			marginLeft: "2px",
																			[`& .${chipClasses.icon}`]: {
																				color: "#fff",
																				display: "flex",
																				alignItems: "center",
																				paddingLeft: "12px",
																			},
																		}}
																	/>
																</Box>
															</Box>
														</Grid>
													</Grid>
												))}
											</Box>
										</Box>
									</Box>
								</div>
							</Card>
						}
					</>
				)}
			</Grid>
		</Grid>
	);

}
