import '../css/attempt-drawer.css';
import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, CircularProgress, Divider, List, ListItem, ListItemButton, TextField, Typography }
from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useEssayContext } from "../contexts/context-essay";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop:'4px' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} 
          sx={{
            backgroundColor:'#b9f6ca',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#00c853'
            }
          }} 
        />
      </Box>
      <Box>
        <Typography 
		  variant="body2" 
		  color="text.secondary" 
		  fontSize={"0.7rem"}
		  sx={{
			color: props.recSelected == 1 ? 'rgba(0,0,0,0.9)' : 'text.secondary',
			fontWeight: props.recSelected == 1 ? 'bold' : 'normal', // Add this line
		  }}
		>
		  {`${Math.round(props.value)}%`}
		</Typography>
      </Box>
    </Box>
  );
}

function AttemptDrawer({isSa, essayAddClick, onEssaySelect, onEssayAttemptSelect}) {
  const {essayList, isEssayListLoaded, loadEssayList, essaySelect, isEssayListLoading} = useEssayContext();
  //const [visibleEssays, setVisibleEssays] = useState([]);
  const [filterEssay, setFilterEssay] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [attemptSelectedId, setAttemptSelectedId] = React.useState('');
  const chipSampleColors = {
    '1': 'rgba(19,130,117,1)',
    '0': 'white'
  };
  const drawerItemColrs = {
    '1x': 'rgba(0,0,0,0.25)',
    '1xx': 'rgb(157, 188, 152)',
    '1xxx': 'rgb(99, 136, 137)',
    '1': '#d3d3d3',//'rgba(19,130,117,1)',
    '0x': 'white',
    '0': '#fff'//'rgba(255,255,255,1)',//'rgb(235, 217, 180)',
  };
  const drawerItemColorsText = {
    '1': 'rgba(255,255,255,1)',
    '0': 'rgba(0,0,0,0.6)',
  };

  useEffect(() => {
    //console.log('drawer init - essay list -', essayList);
  }, []);
  useEffect(() => {
    //console.log('drawer - essay list chagned -', essayList);
  }, [essayList]);
  useEffect(()=>{
    //console.log('isLoading',isLoading);
    if(isEssayListLoading != true && isEssayListLoading != false) return;
    setIsLoading(isEssayListLoading);
    //console.log('isLoading >>',isEssayListLoading);
  }, [isEssayListLoading]);

  const listFilterEssay = ()=>{
    var _list = [...essayList];
    if(filterEssay && filterEssay.length>0){
      var _filterText = filterEssay.toLowerCase();
      _list = _list.filter(r=>
        // r.grade.toLowerCase().includes(_filterText)
        // || 
        r.topic.toLowerCase().includes(_filterText)
        // || r.email.toLowerCase().includes(_filterText)
        || r.versionTitle.toLowerCase().includes(_filterText)
        // || r.tTitle.toLowerCase().includes(_filterText)
        // || r.avgScoreP.toString().toLowerCase().includes(_filterText)
      );
    }
   

    // Filter out items whose essayId is in allAttempt
    
	for(var i = 0;i < _list.length;i++)
	{
		
		if(_list[i].essayAttempt && _list[i].essayAttempt.length > 0)
		{
			const latestAttempt = _list[i].essayAttempt[0];
			
			if(!_list[i].essayAttempt.find((x)=>x.essayId == _list[i].essayId)){
				_list[i].essayAttempt.push({		
					essayId: _list[i].essayId,
					createdOnUTC: _list[i].tTitle,
					parentId: _list[i].essayAttempt[0].parentId,
					attemptId: _list[i].essayAttempt.length + 1,
				});
				
				_list[i].essayAttempt.shift();
				const theEssay = _list.find((x)=>x.essayId == latestAttempt.essayId);
				if (theEssay)
				{
					theEssay.essayAttempt = _list[i].essayAttempt;					
					//_list[i] = theEssay;
				}
			}
			
			
		}
	}
	
	var allAttempt = [];
    for (var i = 0; i < _list.length; i++) {
      if (_list[i].essayAttempt) {  // Ensure the condition is correctly accessing properties
        for (var j = 0; j < _list[i].essayAttempt.length; j++) {
          allAttempt.push(_list[i].essayAttempt[j].essayId);
        }
      }
    }
	var filteredList = _list.filter(item => !allAttempt.includes(item.essayId));

    return filteredList; //stableSort(_list, getComparator(tblOrderEssay, tblOrderByEssay));
  };

  const visibleEssays = React.useMemo(
    () =>
      listFilterEssay()
      ,
      [essayList, filterEssay]
  );
  const filterEssayChange = (event)=>{
    setFilterEssay(event.target.value);
  };
  const onSelect = (arg) => {
    console.log('onEssaySelect', arg);
    if(onEssaySelect) onEssaySelect(arg);
    setAttemptSelectedId('');
  };
  
  const onSelectAttempt = (arg, parentEssay) => {	  
    console.log('onSelectAttempt', arg, parentEssay);
	
    if(onEssayAttemptSelect) onEssayAttemptSelect(arg, parentEssay);
    setAttemptSelectedId(arg.essayId);
  };
  
  const onAddClick = ()=>{
    console.log('onAddClick');
    if(essayAddClick) essayAddClick();
  };

  return (
    <>
    <Box sx={{ 
      //backgroundColor:'#fff',
      overflowY: "auto", 
      '&::-webkit-scrollbar': {
        width: '6px',      // Width of the scrollbar
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1', // Track color
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',     // Thumb color
        borderRadius: '4px',   // Border radius
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',     // Thumb color on hover
      },
      marginBottom: '110px' 
    }}>
      <Box width={'100%'}>
        <Box sx={{padding:'5px 5px 0 5px'}}>
          <TextField value={filterEssay} onChange={filterEssayChange} id="txtFilterEssay" label="" variant="standard" placeholder="Search.." fullWidth />
        </Box>
        { isLoading &&
          <div style={{margin:'1rem'}}>
            <CircularProgress />
          </div>
        }
        { !isLoading &&
        <List sx={{ fontFamily:'Noto Sans'}}>
          {/* {['Week 12 - Essay B', 'Week 12 - Essay A', 'Week 11 - Essay B', 'Week 11 - Essay A'].map((text, index) => ( */}
          {visibleEssays.map((rec, index) => (
            <React.Fragment key={rec.essayId}>
              <ListItem 
                sx={{ 
                  backgroundColor: rec.selected? drawerItemColrs[rec.selected] : drawerItemColrs['0'],
                  color: rec.selected? drawerItemColorsText[rec.selected] : drawerItemColorsText['0'],
                  borderBottom:'1px solid #999'
                }} 
                // secondaryAction={
                //   <Chip label={rec.avgScoreP + '%'} sx={{}} />
                // }
                disablePadding>
                <ListItemButton onClick={() => onSelect(rec)}>
                  {/* <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <InboxIcon />}
                  </ListItemIcon> */}
                  <div style={{widht:'100%', flex:1}}>
                    <Box display="flex" alignItems="center">
                      <Typography sx={{fontWeight:'bold', color:rec.selected=='1'?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.6)'}}>
                        {rec.viewTitle}
                      </Typography>
                      { rec.isSample == 1 && 
                        <Box marginLeft="auto">
                          {/* <BookmarksIcon />  */}
                          <Chip label={'Sample'} variant="filled" style={{
                            backgroundColor:chipSampleColors[rec.selected=='1'?'0':'1'],
                            color:chipSampleColors[rec.selected=='1'?'1':'0']
                          }} 
                          sx={{fontSize:'1.0rem', marginTop:'0rem', padding:'0.1px'}}
                          />
                        </Box>
                      }
                      {rec.isSample != 1 && 
                        <Box marginLeft="auto">
                        <Chip label={rec.grade}
                        variant="filled" style={{
                          backgroundColor:chipSampleColors[rec.selected=='1'?'0':'1'],
                          color:chipSampleColors[rec.selected=='1'?'1':'0'],
                          height:'22px'
                        }} 
                        sx={{fontSize:'.8rem', marginTop:'0rem', marginBottom:'0', padding:'0.1px', maxHeight: "28px",}}
                        />
                      </Box>
                      }
                    </Box>
                    <div>
                    {rec.status > 0 ? (
                      <>
                        {rec.status == 5?
                        // recSelected={rec.selected}
                          <LinearProgressWithLabel value={rec.avgScoreP} recSelected={rec.selected}  />
                          :
                          <>
                          { rec.isInProgress &&
                            <CircularProgress style={{width:'20px', height:'20px'}} />
                          }
                          </>
                        }
                      </>
                    ) : (
                      <Typography variant="body2" color="textSecondary">Draft</Typography>
                    )}
                    </div>
                    <Typography
                      sx={{ display: 'inline',
                        //color: rec.selected? drawerItemColorsText[rec.selected] : drawerItemColorsText['0'],
                        color: rec.selected=='1'?'rgba(0,0,0,0.9)':'rgba(0,0,0,0.6)',
                        //opacity: '0.8'
                      }}
                      component="span" variant="body2"
                    >
                      {/* &nbsp;&nbsp; */}
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
					  <span>{rec.tTitle}</span>
					  {(rec.selected == 0 || !rec.selected) && rec.essayAttempt && rec.essayAttempt.length > 0 && (
						<span style={{ fontStyle: 'italic', fontSize: '0.8rem', color: '#274e13' }}>
						 {rec.essayAttempt.length + 1} {rec.essayAttempt.length + 1 > 1 ? 'attempts' : 'attempt'}
 
						</span>
					  )}
					</div>

                      { false && isSa &&
                      <div>
                        <Typography sx={{ color:'blue', display:'inline', opacity:'0.7', fontSize:'0.6rem' }}>
                          {rec.versionTitle} | v{rec.vCorr=='2.00'?'2.0':rec.vCorr=='1.00'?'1.0':rec.vCorr}
                        </Typography>
                      </div>
                      }
                    </Typography>
                  </div>
                  {/* <ListItemText
                    primary={
                      <Typography sx={{fontWeight:'bold'}}>
                        {rec.viewTitle}
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Box sx={{backgroundColor:'red'}}>
                          <LinearProgressWithLabel value={rec.avgScoreP} recSelected={rec.selected} />
                        </Box>
                        <Typography
                          sx={{ display: 'inline',
                            color: rec.selected? drawerItemColorsText[rec.selected] : drawerItemColorsText['0'],
                            opacity: '0.8'
                          }}
                          component="span" variant="body2"
                        >
                          {rec.tTitle}
                        </Typography>
                      </React.Fragment>
                    }/> */}
                </ListItemButton>
              </ListItem>
			        {rec.selected == 1 ? (
					  <div style={{ marginTop: '0' }}>
						{rec.essayAttempt &&
						  rec.essayAttempt.map((rr, index) => {
							const essay = essayList.find(x => x.essayId === rr.essayId);
							const parentEssay = essayList.find(x => x.essayId === rr.parentId);
						
							const descendingIndex = rec.essayAttempt.length - index; // Adjust index for descending order
							return (
							  <div key={rr.essayId}>
                  <div 
                    onClick={() => onSelectAttempt(essay, parentEssay)}  
                    style={{ }}
                    className={`atempt-menu ${attemptSelectedId == rr.essayId? "atempt-menu-selected":""}`}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <span style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                        <span style={{opacity:.7}}>&#187;</span>&nbsp;Attempt {descendingIndex} {/* Display index in descending order */}
                      </span>
                      <Typography 
                        variant="body2" 
                        color="text.secondary" 
                        fontSize={"0.7rem"}
                        sx={{
                          color: 'rgba(0,0,0,0.9)' ,
                          fontWeight: 'bold'
                        }}
                      >
                        {essay ? `${Math.round(essay?.avgScoreP || 0)}%` : '0%'}
                      </Typography>
                    </div>
                    <div style={{ fontSize: '0.8rem', marginTop: '5px', color:'#000' }}>{rr.createdOnUTC}</div>
                  </div>
                  <Divider />
							  </div>
							);
						  })}

					  </div>
					) : null}
              <Divider />
					
            </React.Fragment>
          ))}
        </List>
        }
      </Box>
    </Box>
    <div
      style={{
        position: "absolute",
        bottom: 50,
        right: 0,
        left: 0,
        //backgroundColor: "white",
        height:'60px',
        borderTop: '0 solid rgba(0,0,0,0.12)',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
      }}
    >
      {/* <Typography variant={"subtitle1"} paddingX={2} paddingY={1}>
        + Add New
      </Typography> */}
      <Button className="app-btn-bg" sx={{
        //backgroundColor:'rgb(99, 136, 137)', 
        //":hover":{backgroundColor:'rgb(157, 188, 152)'},
        width:'100%',
        //width: {drawerWidth},
        margin:'0 5px 22px 5px'
        }}
        onClick={onAddClick} color="info" variant="contained" size="large">
        + ADD
      </Button>
    </div>
    </>
  );
}

export default AttemptDrawer;
