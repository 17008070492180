import React, { useState, useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { alpha } from '@mui/material/styles';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import AppTopBar2 from './common/app-top-bar2';
import AppLoading from './common/app-loading';
import {
  Avatar, AppBar, Button, Box, Chip, CircularProgress, Fab, Container, Divider, Drawer,
  List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Select, Toolbar, Typography, MenuItem, FormControl, InputLabel, TextField, Grid
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import PopShare from './essay/pop-share';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneNumber from 'libphonenumber-js';
import { AppHelper } from '../AppHelper';
import { EssayHelper } from '../helpers/essay.helper';
import EssayForm from './essay/essay-form-v2';
import EssayView from './essay/essay-view';
import SpaCheck from './common/spa-check';
import AppTopBar from "./common/app-top-bar";
import PopSuggestion from "./common/pop-suggestion";
import HomeDrawer from "./home-drawer";
import HomeAttempt from "./attempt-drawer";
import { useEssayContext } from "../contexts/context-essay";
import { useUser } from "../UserContext";
const dayjs = require('dayjs');

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props}
          sx={{
            backgroundColor: '#b9f6ca',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#00c853'
            }
          }}
        />
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary" fontSize={"0.7rem"}
          sx={{
            color: props.recSelected == 1 ? 'rgba(0,0,0,0.9)' : 'text.secondary'
          }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}


export default function PageHomeEssay() {
  // const theme = createTheme({
  //   typography: {
  //     fontFamily: 'Noto Sans',
  //   }
  // });
  const navigate = useNavigate();
  var oUser = AppHelper.userCacheGet();
  const isPhoneVerifed = oUser && oUser.phoneNumber && oUser.phoneNumber.length > 0;
  const [iamTEA, setIamTEA] = useState(false);
  const [viewCode, setViewCode] = useState('PHONE');
  const [isLoading, setIsLoading] = useState(false);
  //const [essayList, setEssayList] = useState([]);
  const [countryCode, setCountryCode] = useState('SG');
  const [phoneNumber, setPhoneNumber] = useState('99998888');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('0');
  const [flagUrl, setFlagUrl] = useState('https://flagcdn.com/w320/sg.png');
  const [txtPhoneNoOK, setTxtPhoneNoOK] = useState(true);
  const [grade, setGrade] = useState("");

  const otpRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const [otpErrors, setOtpErrors] = useState([false, false, false, false, false, false]);
  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [viewMode, setViewMode] = React.useState('NEW');
  const [refreshList, setRefreshList] = React.useState(false);
  const [recEssay, setRecEssay] = React.useState({});
  const [parentEssay, setParentEssay] = React.useState(null);
  const [recDetail, setRecDetail] = React.useState({});
  const [autoSelectId, setAutoSelectId] = React.useState('');
  const scoreCodesV2 = [
    { c: 'GM', c2: 'grammar', n: 'Grammar', v: 0, p: 0, eg: [], sub: [], expand: false },
    { c: 'SP', c2: 'spelling', n: 'Spelling', v: 0, p: 0, eg: [], sub: [], expand: false },
    { c: 'VC', c2: 'vocabulary', n: 'Vocabulary', v: 0, p: 0, eg: [], sub: [], expand: false },
    { c: 'ST', c2: 'structure', n: 'Structure', v: 0, p: 0, eg: [], sub: [], expand: false },
    //{ c:'FL', c2: 'flow', n:'Flow', v:0, p:0 },
  ];
  const [filterEssay, setFilterEssay] = React.useState('');
  const [profiles, setProfiles] = React.useState([]);
  const [profileId, setProfileId] = useState('');
  const refTopBar = useRef(null);
  const [isSa, setIsSa] = React.useState(false);
  const [popShowFeedbackOpen, setPopShowFeedbackOpen] = useState(false);
  const [popShareOpen, setPopShareOpen] = React.useState(false);
  const [attemptTitle, setAttemptTitle] = useState('');

  const onShare = async () => {
    setPopShareOpen(true);
  }

  const popShareClose = () => {
    setPopShareOpen(false);
  }

  const { essayList, isEssayListLoaded, loadEssayList, essaySelect, essaySelectAttempt, essayRemove } = useEssayContext();

  useEffect(() => {
    if (!oUser) return;
    //console.log('isEssayListLoaded',isEssayListLoaded);
    if (!isEssayListLoaded) {
      //loadEssayList(oUser);
      return;
    }
    else {
      //console.log('loaded essay list', essayList);
    }
  }, [isEssayListLoaded]);
  useEffect(() => {
    if (!oUser) {
      navigate('/login');
      return;
    }
    setIamTEA(oUser.accountType == 'TEA');
    essaySelect('');
    AppHelper.apiAuditPost("P-H", "");
    if (!oUser.profileId) {
      console.log('no profile id', oUser);
      //navigate('/login');
      //return;
    }
    if (profileId == '') setProfileId(oUser.profileId);

    const fetchIAM = async () => {
      var _res = await AppHelper.apiPost('api/account/iam', { token: oUser.token });
      if (!_res || !_res.status || !_res.data || !_res.data.isSa) {
        return;
      }
      setIsSa(true);
    }
    fetchIAM();
    const fetchProfiles = async () => {
      try {
        const response = await AppHelper.apiPost('api/profile/list', { token: oUser.token });
        if (response && response.status && response.data) {
          setProfiles(response.data);
        } else {
          console.error('Failed to fetch profiles:', response);
        }
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };
    fetchProfiles();
  }, []);
  // useEffect(()=>{

  // },[isLoading])

  // useEffect(async ()=>{
  //   var _resCountries = await AppHelper.api('countries', 'GET');
  //   countryList.splice(0);
  //   for (const [code, name] of Object.entries(_resCountries)) {
  //     countryList.push({code, name: `${name} (${code})`});
  //   }
  // }, [countryList]);

  // useEffect(()=>{
  //   if(countryListLoad){
  //     AppHelper.api('countries', 'GET').then(_resCountries=>{
  //       countryList.splice(0);
  //       for (const [code, name] of Object.entries(_resCountries)) {
  //         countryList.push({code, name: `${name} (${code})`});
  //       }
  //     });
  //     // var _resCountries = await AppHelper.api('countries', 'GET');
  //   }
  // }, [countryListLoad]);

  const fetchEssayList = async () => {
    if (!oUser) {
      navigate('/login');
      return;
    }

    essayList.splice(0);
    setIsLoading(true);
    var _res = await AppHelper.apiPost('api/essay/list/v4', { token: oUser.token, profileId: oUser.profileId });
    setIsLoading(false);
    if (!_res || !_res.status) {
      return;
    }
    _res.data.forEach(r => {
      var _ts = dayjs(r.timestampUtc);
      r.tCode = _ts.format('YYYYMMDDHHmmss');
      //r.tTitle = `Week ${_ts.week()} - ` + _ts.format('DD MMM YYYY');
      r.tTitle = _ts.format("DD MMM YY' hh:mm A");
      r.viewTitle = `${r.topic}`;
      r.version = r.avgScore != null ? 1 : 2;
      console.log("essayAttempt", r.essayAttempt);
      var _score = r.version == 1 ? r.avgScore : r.avgScore2;
      //console.log('r.version',r.version);
      if (r.version == 1) {
        r.avgScore = parseFloat(_score);
        r.avgScoreP = (r.avgScore / 5) * 100;
      }
      else if (r.version == 2) {
        var _scores = [...scoreCodesV2];
        r.version = EssayHelper.scoreVersionNumFromScore(r);
        // if(r.scores && r.scores.length>0 && r.scores[0].version == '2.10')
        //   r.version = 2.1;
        // else if(r.scores && r.scores.length>0 && r.scores[0].version == '2.20')
        //   r.version = 2.2;
        // else if(r.scores && r.scores.length>0 && r.scores[0].version == '3.00')
        //   r.version = 3.0;
        // else if(r.scores && r.scores.length>0 && r.scores[0].version == '3.10')
        //   r.version = 3.1;

        var _v2TotalScore = 0;
        if (r.version == 3.1) {
          _scores.forEach(_rScore => {
            var _arrScRec = r.scores.filter(_x => _x.scoreCode == _rScore.c);
            const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
            _rScore.v = _arrScRec.length > 0 ? (_scSum / _arrScRec.length) : 0;
            _rScore.v = parseFloat(_rScore.v.toFixed(2));
            if (_rScore.c == 'ST') {
              _rScore.v = parseFloat((_rScore.v * 2.5).toFixed(2));
            }
            else if (_rScore.c == 'VC' && _arrScRec.length < 2) {
              _rScore.v = parseFloat((_rScore.v / 2).toFixed(2));
            }
            _v2TotalScore += _rScore.v;
            // console.log('_scSum',_scSum, r);
            // if((r.version == 3 || r.version == 3.1) && (_rScore.c == 'SP' || _rScore.c == 'GM' || _rScore.c == 'VC')){
            //   _scSum = _scSum / 2.5;
            // }
            // _v2TotalScore += _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
          });
          r.avgScoreP = (_v2TotalScore / (10 * 4)) * 100;
        }
        else {
          _scores.forEach(_rScore => {
            var _arrScRec = r.scores.filter(_x => _x.scoreCode == _rScore.c);
            var _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
            //console.log('_scSum',_scSum, r);
            if ((r.version == 3 || r.version == 3.1) && (_rScore.c == 'SP' || _rScore.c == 'GM' || _rScore.c == 'VC')) {
              _scSum = _scSum / 2.5;
            }
            _v2TotalScore += _arrScRec.length > 0 ? (_scSum / _arrScRec.length) : 0;
          });
          r.avgScore = parseFloat(_v2TotalScore.toFixed(2));
          r.avgScoreP = (r.avgScore / 16) * 100;
        }
      }
      r.versionTitle = r.version == 3.1 ? 'v3.1' : r.version == 3 ? 'v3.0' : r.version == 2.2 ? 'v2.2' : r.version == 2.1 ? 'v2.1' : r.version == 2 ? 'v2.0' : 'v.1.0';

      if (r.status > 0 && r.status < 5) {
        var _diffMinutes = dayjs.utc().diff(dayjs(r.timestampUtc), 'minutes');
        if (_diffMinutes < 15) {
          r.isInProgress = true;
        }
      }
    });
    var _list = [...AppHelper.sortList(_res.data, 'tCode', 2)];

    if (AppHelper.sampleEssay()) {
      _list = _list.filter(r => r.essayId != AppHelper.sampleEssay().essayId);
      _list.push(AppHelper.sampleEssay())
    }
    //setEssayList(_list);
    if (autoSelectId && autoSelectId != '') {
      var _recEssay = _list.find(r => r.essayId == autoSelectId);
      if (_recEssay) onEssaySelect(_recEssay);
      setAutoSelectId('');
    }
  };
  const fetchEssayListAndSync = async () => {
    if (!essayList || essayList.length < 1) return;
    var _isAnyInProgress = essayList.find(x => x.isInProgress);
    if (!_isAnyInProgress) return;
    var _res = await AppHelper.apiPost('api/essay/list/v4', { token: oUser.token, profileId: oUser.profileId });
    if (!_res || !_res.status) return;
    if (!_res.data || _res.data.length < 1) return;

    var _essayList = [...essayList];
    console.log('res', _res, _essayList);
    const _newList = _res.data;
    var _isAnyInProgress = false;
    _essayList.forEach(r => {
      console.log("attempt", r.essayAttempt);
      if (!r.isSample) {
        var _arr = _newList.filter(x => x.essayId = r.essayId);
        if (_arr.length > 0) {
          var _newRec = _arr[0];
          console.log('status', _newRec.status, r.status, r.esayId, _newRec.essayId);
          if (_newRec.status != r.status && _newRec.status == 5 && r.isInProgress) {
            console.log('status CHANGED', _newRec.status, r.status, r.essayId, _newRec.essayId);
            r.status = 5;
            r.isInProgress = false;

            if (_newRec.scores) {
              r.scores = _newRec.scores;
              var _scores = [...scoreCodesV2];
              var _v2TotalScore = 0;
              _scores.forEach(_rScore => {
                var _arrScRec = r.scores.filter(_x => _x.scoreCode == _rScore.c);
                const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
                _rScore.v = _arrScRec.length > 0 ? (_scSum / _arrScRec.length) : 0;
                _rScore.v = parseFloat(_rScore.v.toFixed(2));
                if (_rScore.c == 'ST') {
                  _rScore.v = parseFloat((_rScore.v * 2.5).toFixed(2));
                }
                else if (_rScore.c == 'VC' && _arrScRec.length < 2) {
                  _rScore.v = parseFloat((_rScore.v / 2).toFixed(2));
                }
                _v2TotalScore += _rScore.v;
              });
              r.avgScoreP = (_v2TotalScore / (10 * 4)) * 100;
            }

            //setEssayList(_essayList);
          }
          else if (r.status > 0 && r.status < 5) {
            var _diffMinutes = dayjs.utc().diff(dayjs(r.timestampUtc), 'minutes');
            if (_diffMinutes < 15) {
              r.isInProgress = true;
              _isAnyInProgress = true;
            }
          }
        }
        else {
          console.log('not found');
        }
      }
    });
    setTimeout(() => {
      fetchEssayListAndSync(_essayList);
    }, 1000 * 5);
  }

  // useEffect(()=>{
  //   const fetchProfiles = async () => {
  //     try {
  //       const response = await AppHelper.apiPost('api/profile/list', {token: oUser.token});
  //       if (response && response.status && response.data) {
  //         setProfiles(response.data);
  //       } else {
  //         console.error('Failed to fetch profiles:', response);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching profiles:', error);
  //     }
  //   };
  //   fetchEssayList();
  //   fetchProfiles();
  // }, [refreshList]);
  // useEffect(()=>{
  //   fetchEssayListAndSync();
  // }, [essayList]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  var timerEssaySelect;
  const onEssaySelect = (arg) => {
    setRecEssay(arg);
    setViewMode(arg.status == 0 ? 'NEW' : 'VIEW');
    //if(timerEssaySelect) clearTimeout(timerEssaySelect);
    // setTimeout(() => {
    //   setRecEssay(arg);
    // }, 1000*1.2);
    // var _list = essayList;
    // _list.forEach(s=>{
    //   s.selected = s.essayId == arg.essayId ? '1' : '0';
    // });
    essaySelect(arg.essayId);
    //navigate('/profile');
    //console.log('selected');
    if (mobileOpen) setMobileOpen(false);
    //setAttemptTitle('');
  };
  
  const onEssayAttemptSelect = (arg, parentEssay) => {	    
    setRecEssay(arg);
    setViewMode(arg.status == 0 ? 'NEW' : 'VIEW');
	  essaySelectAttempt(arg.essayId, parentEssay.essayId);
    if (mobileOpen) setMobileOpen(false);
    //setAttemptTitle('');
  };
  
  const essayFormDone = (arg) => {
    //console.log('essayFormDone', arg);
    if (arg.code && arg.code == 'NEW') {
      setAutoSelectId(arg.essayId);
      if (oUser) loadEssayList(oUser, arg.essayId);

      setRecEssay({ essayId: arg.essayId });
      setViewMode('VIEW');
    }
    else {
      setRefreshList(!refreshList);
      //if(oUser) loadEssayList(oUser);
    }
  };
  const essayViewDone = (arg) => {
    setRefreshList(!refreshList);
    if (arg.code && arg.code == 'NEW') {
      setAutoSelectId(arg.essayId);
    }
    else if (arg.code && arg.code == 'DELETE') {
      setViewMode('NEW');
      //if(oUser) loadEssayList(oUser);
      essayRemove(arg.essayId);
    }
    else if(arg.code && arg.code == 'CHANGE'){
      var _rec = {...recEssay, essayId: arg.essayId};
      setRecEssay(_rec);
    }
    else setViewMode('NEW');
  };
  const essayDetailLoaded = (arg) => {
    setParentEssay({...arg});
    //setRecDetail(arg);
  }
  const onTryAgain = async () => {
    console.log('try again',recEssay);
    //setParentEssay({...recEssay});
    setViewMode('NEW');
    essaySelect('');
    setRecEssay({
      topic: "",
      text: "",
      essayId: ""
    });
    setAutoSelectId("");
    if (mobileOpen) setMobileOpen(false);

    var _user = AppHelper.userCacheGet();
    if(!_user) return;
    AppHelper.apiPost('api/essay/attempts', { token: _user.token, essayId: recEssay.essayId }).then(_resAttempts => {
      if(_resAttempts.status){
        setAttemptTitle('Attempt ' + (_resAttempts.data.length+2));
        console.log('Attempt ' + (_resAttempts.data.length+2), _resAttempts.data);
      }
    });
  }
  const essayAddClick = () => {
    setParentEssay(null);
    setViewMode('NEW');
    essaySelect('');
    setRecEssay({
      topic: "",
      text: "",
      essayId: ""
    });
    setAutoSelectId("");
    if (mobileOpen) setMobileOpen(false);
  }
  const filterEssayChange = (event) => {
    setFilterEssay(event.target.value);
  };
  const drawerItemColrs = {
    '1x': 'rgba(0,0,0,0.25)',
    '1xx': 'rgb(157, 188, 152)',
    '1xxx': 'rgb(99, 136, 137)',
    '1': '#d3d3d3',//'rgba(19,130,117,1)',
    '0x': 'white',
    '0': '#fff'//'rgba(255,255,255,1)',//'rgb(235, 217, 180)',
  };
  const chipSampleColors = {
    '1': 'rgba(19,130,117,1)',
    '0': 'white'
  };
  const drawerItemColorsText = {
    '1': 'rgba(255,255,255,1)',
    '0': 'rgba(0,0,0,0.6)',
  };
  const listFilterEssay = () => {
    var _list = [...essayList];
    if (filterEssay && filterEssay.length > 0) {
      var _filterText = filterEssay.toLowerCase();
      _list = _list.filter(r =>
        // r.grade.toLowerCase().includes(_filterText)
        // || 
        //r.topic.toLowerCase().includes(_filterText)
        // || r.email.toLowerCase().includes(_filterText)
        r.versionTitle.toLowerCase().includes(_filterText)
        // || r.tTitle.toLowerCase().includes(_filterText)
        // || r.avgScoreP.toString().toLowerCase().includes(_filterText)
      );
    }
    return _list;//stableSort(_list, getComparator(tblOrderEssay, tblOrderByEssay));
  };
  const visibleEssays = React.useMemo(
    () =>
      listFilterEssay()
    ,
    [essayList, filterEssay]
  );
  const profileSelect = async (arg) => {
    console.log(arg.target.value);
    setProfileId(arg.target.value);
    var _profile = profiles.find(p => p.profileId == arg.target.value);
    if (!_profile) {
      alert('Profile data not found!');
      return;
    }
    var _oUserSwitch = { ...oUser };
    _oUserSwitch.name = _profile.name;
    _oUserSwitch.profileId = _profile.profileId;
    _oUserSwitch.grade = _profile.grade;
    _oUserSwitch.picture = _profile.photo;
    AppHelper.userCacheSet(_oUserSwitch);

    //setRefreshList(!refreshList);
    oUser = AppHelper.userCacheGet();
    loadEssayList(oUser);
    if (refTopBar) {
	  setGrade(_profile.grade);
	  refTopBar.current.refreshProfile();
    }
  };
  const drawer = (
    <>
      <Box sx={{
        //backgroundColor:'#fff',
        overflowY: "auto",
        '&::-webkit-scrollbar': {
          width: '6px',      // Width of the scrollbar
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',     // Thumb color
          borderRadius: '4px',   // Border radius
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',     // Thumb color on hover
        },
        marginBottom: '110px'
      }}>
        <Box width={'100%'}>
          <Box sx={{ padding: '5px 5px 0 5px' }}>
            <TextField value={filterEssay} onChange={filterEssayChange} id="txtFilterEssay" label="" variant="standard" placeholder="Search.." fullWidth />
          </Box>
          <List sx={{ fontFamily: 'Noto Sans' }}>
            {/* {['Week 12 - Essay B', 'Week 12 - Essay A', 'Week 11 - Essay B', 'Week 11 - Essay A'].map((text, index) => ( */}
            {visibleEssays.map((rec, index) => (
              <React.Fragment key={rec.essayId}>
                <ListItem
                  sx={{
                    backgroundColor: rec.selected ? drawerItemColrs[rec.selected] : drawerItemColrs['0'],
                    color: rec.selected ? drawerItemColorsText[rec.selected] : drawerItemColorsText['0'],
                    borderBottom: '1px solid #999'
                  }}
                  // secondaryAction={
                  //   <Chip label={rec.avgScoreP + '%'} sx={{}} />
                  // }
                  disablePadding>
                  <ListItemButton onClick={() => onEssaySelect(rec)}>
                    {/* <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <InboxIcon />}
                  </ListItemIcon> */}
                    <div style={{ widht: '100%', flex: 1 }}>
                      <Box display="flex" alignItems="center">
                        <Typography sx={{ fontWeight: 'bold', color: rec.selected == '1' ? 'rgba(0,0,0,0.9)' : 'rgba(0,0,0,0.6)' }}>
                          {rec.viewTitle}
                        </Typography>
                        {rec.isSample == 1 &&
                          <Box marginLeft="auto">
                            {/* <BookmarksIcon />  */}
                            <Chip label={'Sample'} variant="filled" style={{
                              backgroundColor: chipSampleColors[rec.selected == '1' ? '0' : '1'],
                              color: chipSampleColors[rec.selected == '1' ? '1' : '0']
                            }} />
                          </Box>
                        }
                      </Box>
                      <div>
                        {rec.status > 0 ? (
                          <>
                            {rec.status == 5 ?
                              // recSelected={rec.selected}
                              <LinearProgressWithLabel value={rec.avgScoreP} />
                              :
                              <>
                                {rec.isInProgress &&
                                  <CircularProgress style={{ width: '20px', height: '20px' }} />
                                }
                              </>
                            }
                          </>
                        ) : (
                          <Typography variant="body2" color="textSecondary">Draft</Typography>
                        )}
                      </div>
                      <Typography
                        sx={{
                          display: 'inline',
                          //color: rec.selected? drawerItemColorsText[rec.selected] : drawerItemColorsText['0'],
                          color: rec.selected == '1' ? 'rgba(0,0,0,0.9)' : 'rgba(0,0,0,0.6)',
                          //opacity: '0.8'
                        }}
                        component="span" variant="body2"
                      >
                        {/* &nbsp;&nbsp; */}
                        {rec.tTitle}
                        {isSa &&
                          <div>
                            <Typography sx={{ color: 'blue', display: 'inline', opacity: '0.7', fontSize: '0.6rem' }}>
                              {rec.versionTitle} | v{rec.vCorr == '2.00' ? '2.0' : rec.vCorr == '1.00' ? '1.0' : rec.vCorr}
                            </Typography>
                          </div>
                        }
                      </Typography>
                    </div>
                    {/* <ListItemText
                    primary={
                      <Typography sx={{fontWeight:'bold'}}>
                        {rec.viewTitle}
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Box sx={{backgroundColor:'red'}}>
                          <LinearProgressWithLabel value={rec.avgScoreP} recSelected={rec.selected} />
                        </Box>
                        <Typography
                          sx={{ display: 'inline',
                            color: rec.selected? drawerItemColorsText[rec.selected] : drawerItemColorsText['0'],
                            opacity: '0.8'
                          }}
                          component="span" variant="body2"
                        >
                          {rec.tTitle}
                        </Typography>
                      </React.Fragment>
                    }/> */}
                  </ListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Box>
      <div
        style={{
          position: "absolute",
          bottom: 50,
          right: 0,
          left: 0,
          //backgroundColor: "white",
          height: '60px',
          borderTop: '0 solid rgba(0,0,0,0.12)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {/* <Typography variant={"subtitle1"} paddingX={2} paddingY={1}>
        + Add New
      </Typography> */}
        <Button className="app-btn-bg" sx={{
          //backgroundColor:'rgb(99, 136, 137)', 
          //":hover":{backgroundColor:'rgb(157, 188, 152)'},
          width: '100%',
          //width: {drawerWidth},
          margin: '0 5px 22px 5px'
        }}
          onClick={essayAddClick} color="info" variant="contained" size="large">
          + ADD
        </Button>
      </div>
    </>
  );
  const drawer2 = (
    <Box sx={{ backgroundColor: 'rgba(0,0,0,0)', height: '100%' }} display={'flex'} flexDirection={'column'}>
      {/* <Toolbar sx={{backgroundColor:'#638889'}}>

      </Toolbar> */}
      {/* <Divider /> */}
      <Box width={'100%'}>
        <List sx={{ fontFamily: 'Noto Sans' }}>
          {/* {['Week 12 - Essay B', 'Week 12 - Essay A', 'Week 11 - Essay B', 'Week 11 - Essay A'].map((text, index) => ( */}
          {essayList.map((rec, index) => (
            <React.Fragment key={rec.essayId}>
              <ListItem
                sx={{
                  backgroundColor: rec.selected ? drawerItemColrs[rec.selected] : drawerItemColrs['0'],
                  color: rec.selected ? drawerItemColorsText[rec.selected] : drawerItemColorsText['0'],
                }}
                // secondaryAction={
                //   <Chip label={rec.avgScoreP + '%'} sx={{}} />
                // }
                disablePadding>
                <ListItemButton onClick={() => onEssaySelect(rec)}>
                  {/* <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <InboxIcon />}
                  </ListItemIcon> */}
                  <ListItemText
                    primary={
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {rec.viewTitle}
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Box>
                          <LinearProgressWithLabel value={rec.avgScoreP} recSelected={rec.selected} />
                          {/* <Box>
                            {rec.avgScoreP + '%'}
                          </Box> */}
                        </Box>
                        <Typography
                          sx={{
                            display: 'inline',
                            color: rec.selected ? drawerItemColorsText[rec.selected] : drawerItemColorsText['0'],
                            opacity: '0.8'
                          }}
                          component="span" variant="body2"
                        >
                          {rec.tTitle}
                        </Typography>
                        {/* {" — Wish I could come, but I'm out of town this…"} */}

                      </React.Fragment>
                    } />
                </ListItemButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Box height={'60px'}>
        &nbsp;
      </Box>
      <Box
        sx={{ position: 'fixed', bottom: 0, left: 0, textAlign: 'center', width: { drawerWidth } }}
      >
        <Button sx={{
          backgroundColor: 'rgb(99, 136, 137)',
          ":hover": { backgroundColor: 'rgb(157, 188, 152)' },
          width: '100%',
          //width: {drawerWidth},
          margin: '0 0 5px 5px'
        }}
          onClick={essayAddClick} color="info" variant="contained" size="large">
          + ADD
        </Button>
      </Box>
      {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <InboxIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );
  const mobileOpenClick = (arg) => {
    setMobileOpen(!mobileOpen);
    console.log('mobileOpenClick', mobileOpen);
  };
  const onPopFeedback = () => {
    setPopShowFeedbackOpen(true);
  }
  const popShowFeedbackClose = () => {
    setPopShowFeedbackOpen(false);
  };

  const handleDeleteDraft = (draftId) => {
    //fetchEssayList();
    setViewMode('NEW');
    //if(oUser) loadEssayList(oUser);
    essayRemove(draftId);
  };

  const container = window !== undefined ? () => window.document.body : undefined;
  return (
    <Box sx={{ display: 'flex' }}>
      <AppTopBar2 mobileOpen={mobileOpen} mobileOpenClick={mobileOpenClick} ref={refTopBar} isEssay={true}/>
      {/* <AppTopBar ref={refTopBar} /> */}
      {/* <AppTopBar2 drawerWidth={drawerWidth} /> */}
      {/* <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography>
        </Toolbar>
      </AppBar> */}
      {isLoading ? (
        <AppLoading />
      ) : (
        <>
          <Box backgroundColor="#000"
            component="nav"
            sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0, md: 0 }, border: '0 solid red' }}
          >
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'block', md: 'none' },
                //'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                minWidth: '300px',
              }}
            >
              {/* {drawer} */}
              <HomeAttempt onEssayAttemptSelect={onEssayAttemptSelect} onEssaySelect={onEssaySelect} essayAddClick={essayAddClick} isSa={isSa} />
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'none', md: 'block' },
                //'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                minWidth: '300px'
              }}
              PaperProps={{
                sx: {
                  backgroundColor: '#f1f1f1',
                  //backgroundColor: "#f9efdb",
                  //color: "red",
                  marginTop: '65px',
                  width: drawerWidth
                }
              }}
              open
            >
              {/* {drawer} */}
              <HomeAttempt onEssayAttemptSelect={onEssayAttemptSelect} onEssaySelect={onEssaySelect} essayAddClick={essayAddClick} isSa={isSa} />
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px' }}
          >
            <Grid container>
              <Grid item xs={4}>
                {profiles && profiles.length > 1 && (
                  <Select labelId='lblProfile'
                    value={profileId}
                    onChange={profileSelect}
                    label=""
                    sx={{ "& fieldset": { border: 'none' }, marginLeft: '-12px' }}
                    
                  >
                    {profiles.map((item) => (
                      <MenuItem key={item.profileId} value={item.profileId}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Grid>
              { recEssay.status != 0 && <Grid item xs={4} style={{ textAlign: 'center', alignContent: 'center' }}>
                {recEssay && recEssay.essayId && recEssay.isSample != 1 &&
                  <Button className="app-btn-bg" //sx={{backgroundColor:'rgba(19,130,117,1)', ":hover":{backgroundColor:'#00695c'}}} 
                    onClick={onTryAgain} color="info" variant="contained">TRY AGAIN</Button>
                }
                { (viewMode=='NEW' && parentEssay) &&
                <div style={{fontSize:'1.3rem', fontWeight:'bold'}}>{attemptTitle}</div>
                }
			  
				</Grid>
			  }
              { recEssay.status > 0 &&  <Grid item xs={4} style={{ textAlign: 'right', alignContent: 'center' }}>
                {recEssay && recEssay.essayId && recEssay.isSample != 1 &&
                  <Button className="app-btn-bg" //sx={{backgroundColor:'rgba(19,130,117,1)', ":hover":{backgroundColor:'#00695c'}}} 
                    onClick={onShare} color="info" variant="contained">SHARE</Button>
                }
              </Grid>
			  }
            </Grid>

            {
              viewMode == 'NEW' &&
              <EssayForm profileGrade={grade} parentEssay={parentEssay} essayId={recEssay.essayId} onDeleteDraft={handleDeleteDraft} essayFormDone={essayFormDone} profileId={profileId} isSa={isSa}></EssayForm>
            }
			{ /* recEssay.essayId */}
            {
              viewMode == 'VIEW' &&
              <EssayView essayDetailLoaded={essayDetailLoaded} essayFormDone={essayViewDone} essayId={recEssay.essayId} profileId={profileId} isSa={isSa} isSample={recEssay && recEssay.isSample}></EssayView>
            }
          </Box>
        </>
        // <Container component="main" maxWidth="sm" sx={{ mb: 5 }}>
        //   <Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} elevation={3}>
        //     <Box display='flex' justifyContent='center' alignItems='center' sx={{mb: {xs:3, md:6}}}>
        //         {/* {flagUrl &&
        //           <Avatar src={flagUrl} referrerPolicy="no-referrer" sx={{marginRight:'5px', border:'1px solid #d3d3d3'}} />
        //         } */}
        //         <Typography component="h2" variant="h5" align="center" >
        //           {viewCode=='PHONE'?'STUDENT': 'XX'}
        //         </Typography>
        //     </Box>
        //   </Paper>
        // </Container>
      )}
      <SpaCheck></SpaCheck>
      <PopSuggestion
        open={popShowFeedbackOpen}
        onClose={popShowFeedbackClose}
        essayId={recEssay.essayId}
      // onSubmit={handleFeedbackSubmit}
      // feedbackData={feedbackData}
      // setFeedbackData={setFeedbackData}
      />

      <PopShare
        open={popShareOpen} onClose={popShareClose}

        essay={recEssay}
        allowMark={iamTEA}

      />
    </Box>
  );
}
