import React, {useState, useEffect} from "react";
import { Line } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

export default function ReportCardChart({argData}) {
  const data = {
    labels: [1,2,3,4,5,6,7],
    datasets: [
      {
        label: "",
        data: [],//[68, 56, 61, 58, 78, 56, 58],
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        tension: 0.4, // Smooth line
        pointRadius: 5, // Point size
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };
  const [chartData, setChartData] = useState(data);

  useEffect(()=>{
    if(!argData) return;
    var _chartData = {...chartData};
    _chartData.labels = data.labels.slice(0, argData.length);
    _chartData.datasets[0].data = argData.map(r=>r.score);
    // console.log('length',_chartData.datasets[0].data.length);
    // if(_chartData.datasets[0].data.length==1){
    //   console.log('YES YES');
    //   _chartData.datasets[0].data = [null, _chartData.datasets[0].data[0], null];
    // }
    setChartData(_chartData);
  }, [argData]);

  const options = {
    responsive: true,
    // animation: {
    //   duration: 2000, // Animation time (2 seconds)
    //   easing: "easeInOutQuart", // Smooth easing
    //   from: 1, // Start from y=0
    // },
    interaction: {
      mode: 'nearest', // Shows tooltip when hovering near the line
      intersect: false // Ensures tooltip appears even when not directly on a point
    },
    plugins: {
      legend: { position: "top", display: false },
      title: { display: false, text: "" },
      datalabels: {
        align: "top",
        backgroundColor: '#fff',
        borderColor: '#999',
        borderWidth: 1,
        borderRadius: 6,
        anchor: "end",
        color: "black",
        font: { weight: "bold", size: 12 },
        formatter: (value) => `${value}%`, // Format value with $
      },
      tooltip:{
        callbacks:{
          title: (tooltipItems) => {
            const index = tooltipItems[0].dataIndex;
            //console.log(argData);
            return argData[index].title; // Show 'name' in tooltip title
          },
          label: (tooltipItem) => `Score: ${tooltipItem.raw}%`,
          afterLabel: (tooltipItem) => {
            const index = tooltipItem.dataIndex;
            return `${argData[index].time}`; // Add date as an extra line
          },
        }
      }
    },
    scales: {
      x: {
        type: 'linear',
        ticks: { display: false },
        grid: { display: true },
        title: { display: false, text: "Month" },
      },
      y: {
        title: { display: false, text: "Revenue ($)" },
        min: 1,
        max: 100,
        ticks: {
          stepSize: 25,
        },
      },
    },
    layout: {
      padding: {
        right: 21,  // Adjust the padding to give more space on the right
        top: 33,
      },
    },
  };

  return <Line data={chartData} options={options} key={JSON.stringify(chartData)} />;
}