import React, {useState, useEffect} from "react";
import { PolarArea } from "react-chartjs-2";
import { Chart, RadialLinearScale, ArcElement, Tooltip, Legend } from "chart.js";

// Register components for Polar Area Chart
Chart.register(RadialLinearScale, ArcElement, Tooltip, Legend);

export default function ReportCardChartPolar({argData}) {
  const data = {
    labels: [
      "Relevance & Logic", "Development", 
      "Grammar", "Spelling", 
      "Vocabulary", "Structure & Flow"],
    datasets: [
      {
        label: "Score",
        data: [],//[11, 16, 7, 14, 8, 5],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(161, 136, 127, 0.8)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const [chartData, setChartData] = useState(data);
  useEffect(()=>{
    if(!argData) return;
    var _chartData = {...chartData};
    _chartData.datasets[0].data = argData.map(r=>r.score);
    setChartData(_chartData);
    //console.log('chart data', argData, _chartData);
  }, [argData]);

  const options = {
    responsive: true,
    plugins: {
      legend: { display:false, position: "bottom" },
      tooltip: { enabled: true, },
      datalabels: { display: false, },
    },
    // scales: {
    //   r: {
    //     beginAtZero: true,
    //     suggestedMax: 20, // Adjust max value for better visibility
    //   },
    // },
    scales: {
      r: {
        ticks: { display:false },
        pointLabels: {
          display: true,
          centerPointLabels: true,
          font: {
            size: 12
          }
        }
      }
    },
  };

  return <PolarArea data={chartData} options={options} key={JSON.stringify(chartData)} />;
}