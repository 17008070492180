import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Box,
  Button,
  FormControl,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Select,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress, Tooltip, IconButton
} from "@mui/material";
import Resizer from 'react-image-file-resizer';
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import Jimp from "jimp";
import "../../App.css";
import '../../css/scan-popup.css';
import { useUser } from "../../UserContext";
import { AppHelper } from "../../AppHelper";

const ScanPopup = forwardRef(
  (
    { isOpen, isCropped, onClose, onScanComplete, onCamOpen, isSa, isClear, scanAgain },
    ref
  ) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState("2.1");

    const [pages, setPages] = useState([
      { b64: null, align: false, order: 0, rotation: 0 },
    ]);
    const fileInputRef = useRef(null);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [fileIndex, setFileIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isEnhancedTranscription, setIsEnhancedTranscription] = useState(true);
    const oUser = AppHelper.userCacheGet();
    useEffect(()=>{
      //handleFileChangeRead(0);
    }, [uploadFiles]);
    useEffect(()=>{
      //console.log('pages', pages);
      
      setTimeout(() => {
      }, 1000*3);
    }, [pages]);
    useEffect(()=>{
      // console.log('fileIndex', fileIndex, pages.length, uploadFiles.length, uploadFiles);
      // if(pages.length<uploadFiles.length){
      //   console.log('index >> ', fileIndex+1);
      //   handleAdd(pages.length+1);
      //   handleFileChangeRead(pages.length);
      // }
    }, [fileIndex]);

    const handleAdd = (argIndex)=>{
      setPages([...pages, { b64: null, align: false }]);
    }
    const handleAlign = async (argIndex)=>{
      var _pages = [...pages];
      var _page = _pages[argIndex];
      _page.align = !_page.align;
      if(_page.align){
        _page.alignLoading = true;
        setPages(_pages);
        await alignV2_1_S1(_page.b64, argIndex, _page.fileType, _page.fileSize, _page.isHeicFile);
      }
      else{
        _page.alignLoading = false;
        setPages(_pages);
      }
    }
    const alignV2_1_S1 = async (argB64, argImgIndex, fileType='', fileSize, isHeic=false) => {
      if(isHeic){
        console.log('heic <<<<');
        console.log(argB64);
        var _resHeic2Jpeg = await AppHelper.apiPost("api/convert/heic2Jpeg", {
          b64: argB64,
        });
        console.log(_resHeic2Jpeg);
        if(!_resHeic2Jpeg.status){
          console.log('failed', _resHeic2Jpeg);
          return;
        }
        argB64 = _resHeic2Jpeg.data;
      }
      var _resData;
      var _res = await AppHelper.apiPost("api/essay/scan/2.1/s1", {
        token: "",
        imgs: [argB64],
      });
      if (
        !_res.status ||
        (!_res.data && _res.data.length < 1) ||
        !_res.data[0].status ||
        !_res.data[0].data
      ) {
        alert("Failed to upload. Please refresh and try again!");
        return;
      }
      //console.log(_res.data[0]);
      var _requesId = _res.data[0].data;
      var _newFileSize = '';
      if(_res.isResized && _res.isResized>0){
        _newFileSize = '*** ' + formatFileSize(_res.isResized);
      }
      //console.log("_requesId", _requesId);

      await new Promise((resolve) => setTimeout(resolve, 1000 * 1)); //-- 1sec wait before checking the result
      var _waiting = true;

      var _iWait = 0;
      while (_waiting) {
        _iWait++;
        var _resResult = await AppHelper.apiPost(
          "api/essay/scan/2.1/s1/result",
          { token: "", requestId: _requesId }
        );
        if (!_resResult || !_resResult.status) {
          console.log(_resResult);
          _waiting = false;
        } else if (_resResult.data) {
          _resData = _resResult.data;
          _waiting = false;
        } else {
          console.log("wait.. " + _iWait);
          await new Promise((resolve) => setTimeout(resolve, 1000 * 1));
        }
        if(_iWait>30){
          console.log('exit wait.. after 30sec');
          _waiting = false;
        }
      }
      if (!_resData) {
        // alert(
        //   "Failed to read the upload result data. Please refresh and try again!"
        // );
        // return;
        _resData = argB64;
      }
      //console.log("_resData", _resData);
      //console.log("argB64", argB64);


      // const updatedPages = pages.map((page, index) =>
      //   index === argImgIndex
      //     ? {
      //         ...page,
      //         alignB64: _resData,
      //         align: true,
      //         alignLoading: false
      //         // originalImage: argB64,
      //         // useOriginalImage: originalImages[selectedIndex] || false,
      //         // fileType: fileType,
      //         // fileSize: formatFileSize(fileSize),
      //         // newFileSize: _newFileSize
      //       }
      //     : page
      // );
      // setPages(updatedPages);
      setPages((prev) =>
        prev.map((item, index) =>
          index === argImgIndex ? { ...item, alignB64: _resData, align: true, alignLoading: false } : item
        )
      );

      // setPages((prevPages) => {
      //   return prevPages.map((page, index) =>
      //     index === argImgIndex
      //       ? {
      //           ...page,
      //           imageBase64: _resData,
      //           originalImage: argB64,
      //           useOriginalImage: originalImages[selectedIndex] || false,
      //           fileType: fileType,
      //           fileSize: formatFileSize(fileSize),
      //           newFileSize: _newFileSize
      //         }
      //       : page
      //   );
      // });
      // setLoadingPages((prevLoadingPages) =>
      //   prevLoadingPages.map((_, index) =>
      //     index === argImgIndex ? false : false
      //   )
      // );
    };
    function formatFileSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Bytes';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
      return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
    }
    const handleBrowse = (argIndex)=>{
      //setFileIndex(argIndex);
      fileInputRef.current.click();
    }
    const handleClose = () => {
      // if (isClear) {
      //   setNumPages(1);
      //   setPages([{ imageBase64: null }]);
      //   setOriginalImages(Array(numPages).fill(false));
      // }
      onClose();
    };
    const handleFileChange = async (event) => {
      if(!event.target.files || event.target.files.length<1) return;
      var _files = [...event.target.files];
      _files = AppHelper.sortList(_files, 'name');
      setUploadFiles(_files);
      if (fileInputRef.current) fileInputRef.current.value = ""; // reset file control
      var _pages = [...pages];
      var _startIndex = _pages.length-1;
      for(var i=0; i<_files.length; i++){
        if(_pages.length<i+1) _pages = [..._pages, {b64: null, align: false, order:_startIndex+i}];
        console.log('p1',_pages);
        var _page = _pages[_startIndex+i];
        var _pageData = await pageDataRead(_files[i]);
        //await handleFileChangeRead(i);
        _page = {..._page, ..._pageData};
        console.log(_page);
        _pages[_startIndex+i] = _page;
      }
      console.log('p2>>',_pages);
      setPages(_pages);
    };
    const pageDataRead = async(argFile)=>{
      try {
        const reader = new FileReader();
        reader.readAsDataURL(argFile);
        const isHeicFile = argFile.name.toLowerCase().endsWith('.heic');
        if (isCropped) {//-- disabled crop fun for now (original code in scan-popup-v1)
        }
        else{
          await new Promise((resolveReader, rejectReader) => {
            reader.onload = () => {
              resolveReader();
            };
            reader.onerror = (error) => {
              rejectReader(error);
            };
          })
          const base64 = reader.result.split(",")[1];
          return { b64: base64, fileType:argFile.type, fileSize:argFile.size, isHeicFile:isHeicFile, align:false };
        }
      }
      catch (error) {
        console.error("Error converting image to base64:", error);
        return null;
      }
    }
    const handleFileChangeRead = async(argIndex=0)=>{
      if(!uploadFiles || uploadFiles.length<=argIndex){
        //alert('Invalid File List or Index ' + argIndex);
        return;
      }
      const _file = uploadFiles[argIndex];
      try {
        const reader = new FileReader();
        reader.readAsDataURL(_file);
        const isHeicFile = _file.name.toLowerCase().endsWith('.heic');
        if (isCropped) {//-- disabled crop fun for now (original code in scan-popup-v1)
        }
        else{
          await new Promise((resolveReader, rejectReader) => {
            reader.onload = () => {
              resolveReader();
            };
            reader.onerror = (error) => {
              rejectReader(error);
            };
          })
          const base64 = reader.result.split(",")[1];
          const updatedPages = pages.map((page, index) =>
            index === argIndex
              ? { ...page, b64: base64, fileType:_file.type, fileSize:_file.size, isHeicFile:isHeicFile, align:false }
              : page
          );
          setPages(updatedPages);
          setFileIndex(argIndex);
        }
      }
      catch (error) {
        console.error("Error converting image to base64:", error);
      }
    }
    const handleRemove = (argIndex)=>{
      setPages(pages.slice(0, -1));
    }
    const handleRetake = async(argIndex)=>{
      var _pages = [...pages];
      _pages[argIndex].b64 = null;
      setPages(_pages);
    }
    const orderDown = (argIndex)=>{
      if(argIndex == pages.length-1) return;
      var _pages = [...pages];
      var _rec = {...pages[argIndex]};
      var _recDown = {...pages[argIndex+1]};
      _pages[argIndex] = _recDown;
      _pages[argIndex+1] = _rec;
      setPages(_pages);
    }
    const orderUp = (argIndex)=>{
      if(argIndex == 0) return;
      var _pages = [...pages];
      var _rec = {...pages[argIndex]};
      var _recUp = {...pages[argIndex-1]};
      _pages[argIndex] = _recUp;
      _pages[argIndex-1] = _rec;
      setPages(_pages);
    }

    const handleRotate = async (index) => {
      const page = pages[index];
      if (!page.b64) {
        console.log("No image data found.");
        return;
      }
      setIsLoading(true); 
      const image = new Image();
      image.src = `data:image/jpeg;base64,${page.b64}`;
    
      try {
        await new Promise((resolve, reject) => {
          image.onload = () => resolve();
          image.onerror = reject;
        });
    
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
    
        canvas.width = image.height;
        canvas.height = image.width;
    
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(Math.PI / 2); // 90 degrees
        ctx.drawImage(image, -image.width / 2, -image.height / 2);
    
        const rotatedBase64 = canvas.toDataURL('image/jpeg');
        const updatedPages = pages.map((p, i) => {
          if (i === index) {
            console.log("Updating image at index", i);
            return {
              ...p,
              b64: rotatedBase64.split(",")[1],
            };
          }
          return p;
        });
        console.log("Updated pages", updatedPages);
        setPages(updatedPages);
        setIsLoading(false);
      } catch (error) {
        console.error('Error rotating image:', error);
        setIsLoading(false);
      }
    };
    
    const handleScan = async (argEvent, argVer) => {
      const isValid = pages.every((page) => page.b64 !== null);
      if (!isValid) {
        alert("Please upload or capture images for all pages.");
        setIsLoading(false); // Set loading state back to false
        return;
      }
      const totalSize = pages.reduce((acc, page) => {
        const base64String = page.align
          ? page.alignB64
          : page.b64;
        const sizeB = new Buffer(base64String, "base64").length;
        const sizeMB = (sizeB / (1024 * 1024)).toFixed(2);
        return acc + sizeMB;
      }, 0);
      console.log("size MB", totalSize);
      if (totalSize > 30) {
        alert(
          "Total image size exceeds 30 MB. Please reduce the number or size of images."
        );
        setIsLoading(false); // Set loading state back to false
        return;
      }

      setIsLoading(true); // Set loading state to true
      const images = pages.map((page) =>
        page.align ? page.alignB64 : page.b64
      ); // Get all imageBase64 values

      /*const data = {
    "status": true,
    "data": [
    {
    "status": true,
    "data": "\n<!DOCTYPE html>\n<html>\n<head>\n<title>Essay Title Not Visible</title>\n</head>\n<body>\n<p>Good morning teachers and friends, my name is Winona from 5 Agility and I am here today to tell you why you should choose me as the Exco prefect.</p>\n<p>Firstly, in my last four years at Anchor Green primary [school], I've learned the importance of good leadership, responsibility, and teamwork. When I am chosen to serve as an Exco prefect, I am committed to upholding the core values of our school and will contribute positively to our community.</p>\n<p>Secondly, I want to be an Exco prefect because this role gives me an opportunity to help other voice out</p>\n<!-- The essay seems to continue beyond what is visible in the image -->\n</body>\n</html>\n",
    "title": "Essay Title Not Visible",
    "body": "\nGood morning teachers and friends, my name is Winona from 5 Agility and I am here today to tell you why you [should] choose me as the Exco prefect.\nFirstly, in my last four years at Anchor Green primary school, I've learned the importance of good leadership, responsibility, and teamwork. When I am chosen to serve as an Exco prefect, I am committed to upholding the core values of our school and will contribute positively to our community.\nSecondly, I want to be an Exco prefect because this role gives me an opportunity to help other voice out\n\n\n\n"
    }
    ]
    };
    onScanComplete(data);
    onClose();
    return;*/

      for(var i=0;i<images.length;i++){
        const imgVW2 = new Image();
        imgVW2.src = `data:image/jpeg;base64,${images[i]}`;

        // Wrap onload event in a Promise
        await new Promise((resolve, reject) => {
          imgVW2.onload = () => {
            resolve();
          };
          imgVW2.onerror = (error) => {
            reject(error);
          };
        });

        // Once the image has loaded, you can access the dimensions
        const imgVW2_w = imgVW2.naturalWidth;
        const imgVW2_h = imgVW2.naturalHeight;
        //console.log(imgVW2_w, 'x', imgVW2_h);
        const mp = (imgVW2_w * imgVW2_h) / 2000000;  // Calculate megapixels
        const max_mp = 1.5;//2
        //console.log('mp', mp, max_mp);
        if(mp>max_mp){
          console.log('need to resize', mp);
          const scale = Math.sqrt(max_mp / mp);
          const new_height = parseInt(imgVW2_h * scale);
          const new_width = parseInt(imgVW2_w * scale);
          console.log('new', new_width, 'x', new_height);
          console.log('i', i, images.length);
          console.log('before', images[i].length);
          const imgFileOrigin = AppHelper.imgB64ToFile(images[i]);
          const resizedFile = await new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
              imgFileOrigin,
              new_width,
              new_height,
              'JPEG', // Output format
              99, // Quality
              0, // Rotation
              (resizedFile) => {
                resolve(resizedFile);
              },
              'blob' // Output type
            );
          });
          images[i] = await AppHelper.imgFileToB64(resizedFile);
          console.log('after', images[i].length);
        }
      }
      //if(1==1) return;

      var _url = "api/essay/scan";
      if (selectedVersion == "2.0") _url = "api/essay/scan/2.0";
      else if (selectedVersion == "2.1") _url = "api/essay/scan/2.1";
      //else if (selectedVersion == "4.0") _url = "api/essay/scan/4.0";

      if(isEnhancedTranscription) argVer = '4.0';
      if(argVer) _url = "api/essay/scan/" + argVer;
      //console.log('argVer',argVer);

      var _res = await AppHelper.apiPost(_url, { token: "", imgs: images });

      if (!_res || !_res.status) {
        alert("ERROR: Failed to scan!");
        setIsLoading(false); // Set loading state back to false
        return;
      } else {
        setIsLoading(false); // Set loading state back to false
        onScanComplete(_res, images, isEnhancedTranscription);
        onClose();
      }
    };

    return (
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>Scan</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            {isSa && (
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel sx={{ marginTop: "10px" }} id="version-label">
                    Version
                  </InputLabel>
                  <Select
                    labelId="version-label"
                    id="version"
                    value={selectedVersion}
                    onChange={(event) => setSelectedVersion(event.target.value)}
                    label="Version"
                  >
                    <MenuItem value="1.0">1.0</MenuItem>
                    <MenuItem value="2.0">2.0</MenuItem>
                    <MenuItem value="2.1">2.1</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {pages.map((p, iPage) => (
              <Grid item key={'scan_img_' + iPage}>
                <Grid container>
                  <Grid item sx={{ marginRight: "30px" }}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="left"
                      className={p.b64? '':'scan-upload-box'}
                    >
                      {p.b64 && 
                      <>
                        <Box>
                        <FormControlLabel
                          //onClick={() => handleOriginalImageChange(index)}
                          control={
                            <Checkbox
                              color="primary"
                              checked={p.align}
                              disabled={p.alignLoading}
                              //onChange={() => handleOriginalImageChange(index)}
                              onChange={() => handleAlign(iPage)}
                            />
                          }
                          //label="Click here if your page isn't aligned properly"
                          label={p.alignLoading?'Align in progress..':'Align page'}
                          style={{marginRight:0}}
                        />
                        { iPage>0 &&
                        <Button
                          onClick={() => orderUp(iPage)}
                          sx={{ padding:0, margin:0, minWidth:'0', marginLeft:'.2rem'}}
                        >
                          <ArrowCircleUpIcon />
                        </Button>
                        }
                        { iPage+1 < pages.length &&
                        <Button
                          onClick={() => orderDown(iPage)}
                          sx={{ padding:0, margin:0, minWidth:'0', marginLeft:'.2rem'}}
                        >
                          <ArrowCircleDownIcon />
                        </Button>
                        }
                        {/* <Button
                          sx={{ padding:0, margin:0, minWidth:'0', marginLeft:'.2rem'}}
                          onClick={() => handleRotate(iPage)}>
                          <Rotate90DegreesCwIcon
                            sx={{mb: '-0.1px'}}>
                          </Rotate90DegreesCwIcon>
                        </Button> */}
                        </Box>
                        <FormControlLabel
                          control={
                            <Rotate90DegreesCwIcon
                              color="primary"
                              //onChange={() => handleOriginalImageChange(index)}
                              sx={{marginRight: '6px'}}
                            />
                          }
                          //label="Click here if your page isn't aligned properly"
                          onClick={() => handleRotate(iPage)}
                          label={'Rotate image clockwise'}
                          style={{marginLeft: '0px', marginBottom: '5px'}}
                        />
                        { !p.alignLoading &&
                          <img
                            src={`data:image/jpeg;base64,${
                              p.align ? p.alignB64 : p.b64
                            }`}
                            alt={`Page ${iPage + 1}`}
                            style={{ width: "150px", transform: `rotate(${p.rotation}deg)` }}
                          />
                        }
                        <div
                            style={{
                              textAlign: "center",
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          >{`Page ${iPage + 1}`}
                        </div>
                        { iPage == pages.length-1 &&
                        <>
                        <Button
                          onClick={() => handleRetake(iPage)}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <RefreshIcon />
                          RETAKE
                        </Button>
                        </>
                        }
                      </>
                      }
                      {!p.b64 && 
                      <>
                        <Button
                          startIcon={<CloudUploadIcon />}
                          sx={{ marginBottom: "8px" }}
                          onClick={() => handleBrowse(iPage)}
                        >
                          Upload
                        </Button>
                      </>
                      }
                      {/* <Button startIcon={<PhotoCameraIcon />} onClick={()=> handleCamera(index)}>Camera</Button> */}
                    </Grid>
                  </Grid>
                  <Grid item
                    justifyContent="left"
                    alignItems={'center'}
                    sx={{
                      border: "0px solid #ccc",
                      padding: "18px",
                      borderRadius: "4px",
                      marginBottom: "16px",
                      marginTop: "1.3rem",
                    }}
                  >
                    { iPage == pages.length-1 && p.b64 &&
                    <>
                    <Button
                      onClick={handleAdd}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <AddIcon />
                      ADD PAGE
                    </Button>
                    </> 
                    }
                    { iPage == pages.length-1 && iPage != 0 &&
                    <Button
                      onClick={()=>handleRemove(iPage)}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <CancelIcon />
                      REMOVE PAGE
                    </Button>
                    }
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <Grid container justifyContent="center">
                <Button
                  className="app-btn-bg"
                  //sx={{ backgroundColor: 'rgb(99, 136, 137)', ":hover": { backgroundColor: 'rgb(157, 188, 152)' } }}
                  color="info"
                  variant="contained"
                  onClick={handleScan}
                  //disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "SCAN"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/jpeg,image/png,image/bmp/heic/HEIC"
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple='multiple'
          />
        </DialogContent>
        <Button
          onClick={handleClose}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          <CloseIcon />
        </Button>
      </Dialog>
    );
  }
);

export default ScanPopup;
